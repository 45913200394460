import {
  Alert,
  AlertTitle,
  Box,
  Container,
  Grid,
  Radio,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { StudentInfo, Project } from "./App";

import logo from "./img/logo.svg";

interface ProjectsProps {
  token: string;
  student: StudentInfo;
}

function classSuitsYears(studentClass: string, years: number[]): boolean {
  return years.some((year) => {
    const regex = /\d+/;
    const matches = studentClass.match(regex);

    if (matches && matches.length > 0) {
      return parseInt(matches[0]) === year;
    } else {
      return false;
    }
  });
}

function Projects(props: ProjectsProps) {
  const [projects, setProjects] = useState<Project[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [selectedProject, setSelectedProject] = useState<number | undefined>();
  const [currentProject, setCurrentProject] = useState<number | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const updateProjects = (token: string) => {
    return axios
      .get<Project[]>("/api/projects", {
        headers: { token: token },
      })
      .then(
        (data) => {
          const newProjects = data.data.filter((p) =>
            classSuitsYears(props.student.class, p.years)
          );
          setProjects(newProjects);
        },
        (err) => {
          setError(err.data);
        }
      );
  };

  useEffect(() => {
    if (props.student && props.student.project && props.student.project.id) {
      setSelectedProject(props.student.project.id);
      setCurrentProject(props.student.project.id);
    }

    updateProjects(props.token);
  }, [props.token]);

  const submitChoice = () => {
    if (loading) return;
    if (currentProject === selectedProject) return;

    setLoading(true);
    axios
      .post(`/api/students/${props.student.id}/${selectedProject}`, null, {
        headers: { token: props.token },
      })
      .then(
        () => {
          updateProjects(props.token).then(() => {
            setCurrentProject(selectedProject);
            setLoading(false);
            if (!success) {
              setSuccess(true);
              setTimeout(() => {
                setSuccess(false);
              }, 4000);
            }
          });
        },
        (err) => {
          setError(err.response.data);
          setLoading(false);
        }
      );
  };

  const isSelectable = (p: Project) => p.students_count < p.max;

  const chooseProject = (p: Project) => {
    if (isSelectable(p)) setSelectedProject(p.id);
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          paddingTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src={logo}
          sx={{
            height: "4em",
          }}
          alt="Logo"
        />
        <Typography component="h1" variant="h4" marginTop={4}>
          Hallo, {props.student.name}!
        </Typography>
        <Typography>Wähle ein Projekt:</Typography>
        <Grid container spacing={2} alignItems="center">
          {projects.map((p) => (
            <React.Fragment key={p.id}>
              <Grid
                item
                xs={8}
                onClick={() => chooseProject(p)}
                style={{ cursor: isSelectable(p) ? "pointer" : "auto" }}
              >
                <Typography
                  component="h2"
                  variant="h6"
                  style={{ fontWeight: "bold" }}
                >
                  {p.title}
                </Typography>
                <Typography>{p.description}</Typography>
                <Typography>
                  Lehrkräfte:{" "}
                  {p.teachers.length > 0
                    ? p.teachers
                        .map((t) => `${t.lastname}, ${t.firstname}`)
                        .join("; ")
                    : "keine"}
                </Typography>
              </Grid>
              <Grid item xs={2} display="flex" justifyContent="center">
                <Typography>
                  {p.students_count} / {p.max}
                </Typography>
              </Grid>
              <Grid item xs={2} display="flex" justifyContent="center">
                <Radio
                  checked={selectedProject === p.id}
                  disabled={!isSelectable(p)}
                  onChange={() => chooseProject(p)}
                  value={p.id}
                  name={`radio-project-${p.id}`}
                  inputProps={{ "aria-label": p.description }}
                />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={submitChoice}
          loading={loading}
          disabled={currentProject === selectedProject}
        >
          Wahl speichern
        </LoadingButton>
      </Box>
      {error && (
        <Alert severity="error">
          <AlertTitle>Fehler</AlertTitle>
          {error}
        </Alert>
      )}
      {success && (
        <Alert severity="success">
          <AlertTitle>Erfolg</AlertTitle>
          Wahl erfolgreich gespeichert.
        </Alert>
      )}
    </Container>
  );
}

export default Projects;
