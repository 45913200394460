import { Delete } from "@mui/icons-material";
import { Box, Button } from "@mui/material";

export function DeleteButtonCell(props: any): JSX.Element {
  return (
    <Box component="div" display="flex" justifyContent="center">
      <Button color="warning" onClick={props.onClick}>
        <Delete />
      </Button>
    </Box>
  );
}

export default DeleteButtonCell;
