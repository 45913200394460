import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Menu,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import { Architecture, Logout } from "@mui/icons-material";
import { Link, Outlet } from "react-router-dom";
import { useTheme } from "@mui/system";

import logo from "./img/logo.svg";

interface ManageProps {
  token: string;
}

function Manage(props: ManageProps) {
  const theme = useTheme();

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Box
            component="img"
            sx={{
              height: "4em",
            }}
            alt="Logo"
            src={logo}
          />
          <Stack
            direction="row"
            spacing={2}
            sx={{ ml: theme.spacing(2), flexGrow: 1 }}
          >
            <Button component={Link} to="projects" color="inherit">
              Projekte
            </Button>
            <Button component={Link} to="students" color="inherit">
              Schüler/-innen
            </Button>
            <Button component={Link} to="export" color="inherit">
              Daten-Export
            </Button>
          </Stack>
          <IconButton component={Link} to="/" color="inherit">
            <Logout />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container maxWidth="md">
        <Box
          component="div"
          sx={{
            mt: 4,
          }}
        >
          <Outlet />
        </Box>
      </Container>
    </>
  );
}

export default Manage;
