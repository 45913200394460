import { Remove } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Typography,
} from "@mui/material";
import { ColDef, ICellEditorParams } from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import {
  forwardRef,
  Key,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import { Project, Student } from "./App";
import { ProjectService } from "./ProjectService";
import { StudentService } from "./StudentService";

export interface EditStudentsProps {
  token: string;
}

function BooleanCell(props: any): JSX.Element {
  return <Switch defaultChecked={props.value} disabled color="warning" />;
}

function SelectRendererCell(props: any): JSX.Element {
  return props.value ? (
    <>{props.value[props.field]}</>
  ) : (
    <Box
      component="div"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Remove />
    </Box>
  );
}

interface SelectEditorCellProps<
  T extends string | number | readonly string[] | undefined
> extends ICellEditorParams {
  valueField: string;
  nullValueFieldDescription: string;
  possibleValues?: { value: T; display: string }[];
}

const SelectEditorCell = forwardRef(
  (props: SelectEditorCellProps<any>, ref) => {
    const [currentValue, setCurrentValue] = useState<any>();

    useEffect(() => {
      if (props.value) {
        setCurrentValue(props.value[props.valueField]);
      } else {
        setCurrentValue(null);
      }
    }, [props.value]);

    const handleChange = (e: SelectChangeEvent<any>) => {
      if (e.target.value === "") e.target.value = null;
      setCurrentValue(e.target.value);
    };

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return currentValue;
        },
      };
    });

    return (
      <FormControl fullWidth size="small">
        <InputLabel id={`students-table-edit-${props.data.id}-project-label`}>
          Projekt
        </InputLabel>
        <Select
          labelId={`students-table-edit-${props.data.id}-project-label`}
          id={`students-table-edit-${props.data.id}-project`}
          value={currentValue ?? ""}
          label="Projekt"
          onChange={handleChange}
        >
          <MenuItem value="">{props.nullValueFieldDescription}</MenuItem>
          {props.possibleValues &&
            props.possibleValues.map((v) => (
              <MenuItem value={v.value} key={v.display}>
                {v.display}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  }
);

function EditStudents(props: EditStudentsProps) {
  const [students, setStudents] = useState<Student[]>();
  const [projects, setProjects] = useState<Project[]>([]);
  const [error, setError] = useState<string | undefined>();

  const gridRef = useRef<AgGridReact>(null);

  const updateStudents = (token: string) => {
    return StudentService.getAllStudents(token).then(
      (data) => {
        setStudents(data);
      },
      (err) => {
        setError(err.data);
      }
    );
  };

  const updateProjects = (token: string) => {
    return ProjectService.getAllProjects(token).then(
      (data) => {
        setProjects(data);
      },
      (err) => {
        setError(err.data);
      }
    );
  };

  const columns: ColDef[] = [
    {
      field: "firstname",
      headerName: "Vorname",
      flex: 1,
      sortable: true,
      editable: true,
    },
    {
      field: "lastname",
      headerName: "Nachname",
      flex: 1,
      sortable: true,
      editable: true,
    },
    {
      field: "class",
      headerName: "Klasse",
      flex: 0.5,
      sortable: true,
      editable: true,
      valueSetter: (params) => {
        const regexIsClass = /^\d{1,2}[a-z]$/;
        if (regexIsClass.test(params.newValue)) {
          params.data.class = params.newValue;
          return true;
        } else {
          return false;
        }
      },
    },
    {
      field: "admin",
      headerName: "Ist Admin",
      flex: 0.5,
      sortable: true,
      editable: false,
      cellRenderer: BooleanCell,
    },
    {
      field: "project",
      headerName: "Projekt",
      flex: 1,
      editable: true,
      cellRenderer: SelectRendererCell,
      cellRendererParams: {
        field: "title",
      },
      cellEditor: SelectEditorCell,
      cellEditorParams: {
        valueField: "id",
        possibleValues: projects.map((p) => ({
          display: p.title,
          value: p.id,
        })),
        nullValueFieldDescription: "Kein Projekt",
      },
    },
  ];

  const submitUpdateStudent = (newRow: Student): Promise<void> => {
    return StudentService.updateStudent(props.token, newRow.id, newRow).then(
      () => {
        updateStudents(props.token);
      },
      (err) => {
        console.log(err);
        setError(err.message);
      }
    );
  };

  useEffect(() => {
    updateStudents(props.token);
  }, [props.token]);

  useEffect(() => {
    updateProjects(props.token);
  }, [props.token]);

  return (
    <>
      {error && (
        <Alert severity="error" sx={{ mb: 4 }}>
          <AlertTitle>Fehler</AlertTitle>
          {error}
        </Alert>
      )}
      <Box
        component="div"
        className="ag-theme-alpine"
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <Typography component="h1" variant="h4">
          Schülerinnen und Schüler
        </Typography>
        <AgGridReact
          ref={gridRef}
          rowData={students}
          columnDefs={columns}
          domLayout="autoHeight"
          onCellValueChanged={(e) => submitUpdateStudent(e.data)}
          editType="fullRow"
        />
      </Box>
    </>
  );
}

export default EditStudents;
