import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { Project } from "./App";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { ProjectService } from "./ProjectService";

import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Add, Check, Close } from "@mui/icons-material";
import DeleteButtonCell from "./DeleteButtonCell";
import { useNavigate } from "react-router-dom";
import EditButtonCell from "./EditButtonCell";

export interface EditProjectsProps {
  token: string;
}

interface NewProjectFieldValidationStatus {
  title?: Error;
  description?: Error;
  years?: Error;
  max?: Error;
}

function EditProjects(props: EditProjectsProps) {
  const [projects, setProjects] = useState<Project[]>();
  const [error, setError] = useState<string | undefined>();

  const [newProjectValidationStatus, setNewProjectValidationStatus] =
    useState<NewProjectFieldValidationStatus>({});

  const gridRef = useRef<AgGridReact>(null);

  const updateProjects = (token: string) => {
    return ProjectService.getAllProjects(token).then(
      (data) => {
        setProjects(data);
      },
      (err) => {
        setError(err.data);
      }
    );
  };

  function DeleteProjectCell(deleteCellProps: any): JSX.Element {
    const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);

    const deleteProject = () => {
      setConfirmationOpen(true);
    };

    const confirmDelete = () => {
      ProjectService.deleteProject(props.token, deleteCellProps.value)
        .then(() => setConfirmationOpen(false))
        .then(() => updateProjects(props.token));
    };

    const handleAbort = () => {
      setConfirmationOpen(false);
    };

    return (
      <>
        <Dialog open={confirmationOpen} onClose={handleAbort}>
          <DialogTitle id="alert-dialog-title">Löschen bestätigen</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Projekt "{deleteCellProps.data.title}" sicher löschen?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={confirmDelete} variant="contained" color="warning">
              <Check />
            </Button>
            <Button
              onClick={handleAbort}
              autoFocus
              variant="contained"
              color="error"
            >
              <Close />
            </Button>
          </DialogActions>
        </Dialog>
        <DeleteButtonCell onClick={deleteProject} />
      </>
    );
  }

  function EditProjectTeachersCell(deleteCellProps: any): JSX.Element {
    const nav = useNavigate();

    const navigateToProject = () => {
      nav(`/manage/projects/teachers/${deleteCellProps.value}`, {
        replace: true,
      });
    };

    return <EditButtonCell onClick={navigateToProject} />;
  }

  const columns: ColDef[] = [
    {
      field: "title",
      headerName: "Titel",
      flex: 1,
      sortable: true,
      editable: true,
    },
    {
      field: "description",
      headerName: "Beschreibung",
      flex: 1,
      sortable: true,
      editable: true,
    },
    {
      field: "years",
      headerName: "Jahrgänge",
      flex: 0.5,
      sortable: true,
      valueGetter: (params) => params.data.years.join(","),
      valueSetter: (params) => {
        const isYearsExpression = /^\d{1,2}(,\d{1,2})*$/;

        if (!isYearsExpression.test(params.newValue)) return false;

        const years = (params.newValue as string)
          .split(",")
          .map((s) => parseInt(s));

        params.data.years = years;
        return true;
      },
      editable: true,
    },
    {
      field: "max",
      headerName: "Teilnehmer (Max.)",
      flex: 0.5,
      type: "numericColumn",
      sortable: true,
      valueGetter: (params) => params.data.max,
      valueSetter: (params) => {
        if (Number.isInteger(Number(params.newValue))) {
          params.data.max = params.newValue;
          return true;
        } else {
          return false;
        }
      },
      editable: true,
    },
    {
      field: "id",
      headerName: "Löschen",
      flex: 0.5,
      cellRenderer: DeleteProjectCell,
    },
    {
      field: "id",
      headerName: "Lehrer",
      flex: 0.5,
      cellRenderer: EditProjectTeachersCell,
    },
  ];

  const submitUpdateProject = (newRow: Project): Promise<void> => {
    return ProjectService.updateProject(props.token, newRow.id, newRow).then(
      () => {
        updateProjects(props.token);
      }
    );
  };

  const submitAddProject = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    var err: NewProjectFieldValidationStatus = {};

    const dataTitle = data.get("title");
    const dataDescription = data.get("description");

    const dataYears = data.get("years");
    var dataYearsParsed: number[] | undefined = undefined;
    const dataMax = data.get("max");
    var dataMaxParsed: number | undefined = undefined;

    if (!dataTitle) {
      err.title = new Error("Titel darf nicht leer sein.");
    }

    if (!dataDescription) {
      err.description = new Error("Beschreibung darf nicht leer sein.");
    }

    if (!dataYears) {
      err.years = new Error("Jahrgang darf nicht leer sein.");
    } else {
      const isYearsExpression = /^\d{1,2}(,\d{1,2})*$/;

      if (!isYearsExpression.test(dataYears.toString())) {
        err.years = new Error("Jahrgänge sind nicht richtig formatiert.");
      } else {
        dataYearsParsed = dataYears
          .toString()
          .split(",")
          .map((s) => parseInt(s));
      }
    }

    if (!dataMax) {
      err.max = new Error("Maximale Teilnehmerzahl darf nicht leer sein.");
    } else {
      dataMaxParsed = parseInt(dataMax.toString());
      if (isNaN(dataMaxParsed)) {
        err.max = new Error("Maximale Teilnehmerzahl muss eine Zahl sein.");
      }
    }

    if (Object.keys(err).length > 0) {
      setNewProjectValidationStatus(err);
      return;
    } else {
      setNewProjectValidationStatus({});

      e.currentTarget.reset();

      ProjectService.addProject(props.token, {
        id: -1,
        title: dataTitle!.toString(),
        description: dataDescription!.toString(),
        years: dataYearsParsed!,
        max: dataMaxParsed!,
        students_count: -1,
        teachers: [],
      }).then(() => updateProjects(props.token));
    }
  };

  useEffect(() => {
    updateProjects(props.token);
  }, [props.token]);

  return (
    <>
      {error && (
        <Alert severity="error" sx={{ mb: 4 }}>
          <AlertTitle>Fehler</AlertTitle>
          {error}
        </Alert>
      )}
      <Box
        component="div"
        className="ag-theme-alpine"
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <Typography component="h1" variant="h4">
          Projekte
        </Typography>
        <AgGridReact
          ref={gridRef}
          rowData={projects}
          columnDefs={columns}
          domLayout="autoHeight"
          onCellValueChanged={(e) => submitUpdateProject(e.data)}
          editType="fullRow"
        />
      </Box>
      <Box
        component="form"
        onSubmit={submitAddProject}
        noValidate
        sx={{ mt: 4, display: "flex", flexDirection: "column" }}
      >
        <Typography component="h2" variant="h5">
          Projekt hinzufügen
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          name="title"
          label="Titel"
          type="text"
          id="title"
          error={!!newProjectValidationStatus.title}
          helperText={newProjectValidationStatus.title?.message}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="description"
          label="Beschreibung"
          type="text"
          id="description"
          error={!!newProjectValidationStatus.description}
          helperText={newProjectValidationStatus.description?.message}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="years"
          label="Jahrgang"
          id="years"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          error={!!newProjectValidationStatus.years}
          helperText={newProjectValidationStatus.years?.message}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="max"
          label="Maximale Teilnehmer"
          id="max"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          error={!!newProjectValidationStatus.max}
          helperText={newProjectValidationStatus.max?.message}
        />
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2, alignSelf: "center" }}
        >
          <Add />
        </Button>
      </Box>
    </>
  );
}

export default EditProjects;
