import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  CssBaseline,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { StudentInfo } from "./App";

import logo from "./img/logo.svg";

export interface LoginProps {
  onTokenSubmit: (token: string, tokenData: StudentInfo) => void;
}

function Login({ onTokenSubmit }: LoginProps) {
  const [error, setError] = useState<string | undefined>();
  const [searchParams] = useSearchParams();

  const givenToken: string | null = searchParams.get("token");

  const submit = (newToken: string) => {
    axios
      .post<StudentInfo>("/api/students/checkLogin", {
        token: newToken,
      })
      .then(
        (data) => {
          setError(undefined);
          onTokenSubmit(newToken, data.data);
        },
        (err) => {
          setError(err.response.data);
        }
      );
  };

  if (givenToken) {
    submit(givenToken);
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          paddingTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src={logo}
          sx={{
            height: "4em",
          }}
          alt="Logo"
        />
        <Typography component="h1" variant="h5" marginTop={4}>
          Bitte geben Sie Ihren Token ein.
        </Typography>
        <Box
          component="form"
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            const token = new FormData(e.currentTarget).get("token");
            if (token) submit(token.toString());
          }}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            name="token"
            label="Token"
            type="text"
            id="token"
            value={givenToken ? givenToken : undefined}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Prüfen
          </Button>
        </Box>
      </Box>
      {error && (
        <Alert severity="error">
          <AlertTitle>Fehler</AlertTitle>
          {error}
        </Alert>
      )}
    </Container>
  );
}

export default Login;
