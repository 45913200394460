import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useState } from "react";
import Manage from "./Manage";
import Projects from "./Projects";
import Login from "./Login";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import EditProjects from "./EditProjects";
import RedirectToEditProjects from "./RedirectToEditProjects";
import EditStudents from "./EditStudents";

import type {} from "@mui/x-data-grid/themeAugmentation";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import EditProjectTeachers from "./EditProjectTeachers";
import Export from "./Export";

export interface Teacher {
  id: number;
  firstname: string;
  lastname: string;
  short: string;
}

export interface Project {
  id: number;
  description: string;
  max: number;
  title: string;
  years: number[];
  students_count: number;
  teachers: Teacher[];
}

export interface Student {
  id: number;
  firstname: string;
  lastname: string;
  class: string;
  admin: boolean;
  project: Project;
}

export interface StudentInfo {
  success: boolean;
  admin: boolean;
  name: string;
  id: number;
  project: Project;
  class: string;
}

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html, body, #root {
          height: 100%
        }

        .ag-header-cell-label {
          justify-content: center;
        }
      `,
    },
  },
});

function App() {
  const [token, setToken] = useState<string | undefined>();
  const [student, setStudentInfo] = useState<StudentInfo | undefined>();

  const navigate = useNavigate();

  const onSubmitToken = (token: string, data: StudentInfo) => {
    setToken(token);
    setStudentInfo(data);

    if (data.admin) {
      navigate("manage");
    } else {
      navigate("projects");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Login onTokenSubmit={onSubmitToken} />} />
        <Route
          path="projects"
          element={
            token && student ? (
              <Projects token={token} student={student} />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="manage"
          element={token ? <Manage token={token} /> : <Navigate to="/" />}
        >
          <Route index element={token && <EditProjects token={token} />} />
          <Route
            path="projects"
            element={token && <EditProjects token={token} />}
          />
          <Route
            path="projects/teachers/:id"
            element={token && <EditProjectTeachers token={token} />}
          />
          <Route
            path="students"
            element={token && <EditStudents token={token} />}
          />
          <Route
            path="export"
            element={token && <Export token={token} />}
          />
          <Route path="export" element={token && <Export token={token} />} />
        </Route>
        {/* <Route path="*" element={<Navigate to="/" />} /> */}
      </Routes>
    </ThemeProvider>
  );
}

export default App;
