import axios from "axios";
import { Student } from "./App";

export class StudentService {
  public static getAllStudents(token: string): Promise<Student[]> {
    return axios
      .get<Student[]>("/api/students", {
        headers: { token: token },
      })
      .then((data) => data.data);
  }

  public static updateStudent(
    token: string,
    id: number,
    student: Student
  ): Promise<Student> {
    return axios
      .post<Student>(`/api/students/${id}`, student, {
        headers: { token: token },
      })
      .then((data) => data.data);
  }
}
