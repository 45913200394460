import axios from "axios";

function showBinaryData(data: Blob): void {
  var blobURL = window.URL.createObjectURL(data);
  window.open(blobURL, "_blank");
}

export class ExportService {
  public static exportProjectsAsPDF(token: string): Promise<void> {
    return axios
      .get<Blob>(`/api/export/projects/pdf`, {
        headers: {
          token: token,
          Accept: "application/pdf",
        },
        responseType: "blob",
      })
      .then((data) => {
        showBinaryData(data.data);
      });
  }

  public static exportProjectsAsCSV(token: string): Promise<void> {
    return axios
      .get<Blob>(`/api/export/projects/csv`, {
        headers: {
          token: token,
          Accept: "text/csv",
        },
        responseType: "blob",
      })
      .then((data) => {
        console.log(data.data);
      });
  }
}
