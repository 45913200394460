import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { Project, Teacher } from "./App";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { ProjectService } from "./ProjectService";

import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Add, ArrowBack, Check, Close } from "@mui/icons-material";
import DeleteButtonCell from "./DeleteButtonCell";
import { useNavigate, useParams } from "react-router-dom";
import { TeacherService } from "./TeacherService";

export interface EditProjectTeachersProps {
  token: string;
}

function EditProjectTeachers(props: EditProjectTeachersProps) {
  const [project, setProject] = useState<Project>();
  const [teachers, setTeachers] = useState<Teacher[]>([]);

  const [error, setError] = useState<string | undefined>();

  const [checkedTeachers, setCheckedTeachers] = useState<number[]>([]);

  const { id } = useParams();
  const nav = useNavigate();

  const updateProject = (token: string, id: number) => {
    return ProjectService.getSingleProject(token, id).then(
      (data) => {
        setProject(data);
        setCheckedTeachers(data.teachers.map((t) => t.id));
      },
      (err) => {
        setError(err.data);
      }
    );
  };

  const updateTeachers = (token: string) => {
    return TeacherService.getAllTeachers(token).then(
      (data) => {
        setTeachers(data);
      },
      (err) => {
        setError(err.data);
      }
    );
  };

  useEffect(() => {
    if (id) {
      updateProject(props.token, parseInt(id));
    }
  }, [props.token, id]);

  useEffect(() => {
    updateTeachers(props.token);
  }, [props.token]);

  const handleToggle = (t: Teacher) => {
    const indexOfSelectedTeacher = checkedTeachers.indexOf(t.id);
    if (indexOfSelectedTeacher === -1) {
      setCheckedTeachers([...checkedTeachers, t.id]);
    } else {
      var newCheckedTeachers = [...checkedTeachers];
      newCheckedTeachers.splice(indexOfSelectedTeacher);
      setCheckedTeachers(newCheckedTeachers);
    }
  };

  const goBack = () => {
    nav("..");
  };

  const onSaveTeachers = () => {
    if (project) {
      ProjectService.setProjectTeachers(
        props.token,
        project.id,
        checkedTeachers
      ).then(
        (data) => {
          setProject(data);
          setCheckedTeachers(data.teachers.map((t) => t.id));
          goBack();
        },
        (err) => {
          setError(err.data);
        }
      );
    }
  };

  return (
    <>
      {error && (
        <Alert severity="error" sx={{ mb: 4 }}>
          <AlertTitle>Fehler</AlertTitle>
          {error}
        </Alert>
      )}
      {project ? (
        <Box
          component="div"
          className="ag-theme-alpine"
          display="flex"
          flexDirection="column"
          width="100%"
          alignItems="center"
        >
          <Typography component="h1" variant="h4">
            Lehrer des Projektes "{project.title}"
          </Typography>
          <List sx={{ maxWidth: 360, bgcolor: "background.paper" }}>
            {teachers
              .sort((t1, t2) => t1.lastname.localeCompare(t2.lastname))
              .map((t) => {
                const labelId = `checkbox-list-label-${t.id}`;

                return (
                  <ListItem key={t.id} disablePadding>
                    <ListItemButton
                      role={undefined}
                      onClick={() => handleToggle(t)}
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checkedTeachers.indexOf(t.id) !== -1}
                          // tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={`${t.lastname}, ${t.firstname}`}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
          </List>
          <Box sx={{ mt: 1 }}>
            <Button variant="contained" onClick={onSaveTeachers} sx={{ mr: 2 }}>
              <Check />
            </Button>
            <Button
              variant="contained"
              onClick={goBack}
              sx={{ ml: 2 }}
              color="warning"
            >
              <ArrowBack />
            </Button>
          </Box>
          <Box sx={{ mt: 2 }}>
            {error && (
              <Alert severity="error">
                <AlertTitle>Fehler</AlertTitle>
                {error}
              </Alert>
            )}
          </Box>
        </Box>
      ) : (
        <Skeleton />
      )}
    </>
  );
}

export default EditProjectTeachers;
