import { Alert, AlertTitle, Box, Button, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { ExportService } from "./ExportService";

export interface ExportProps {
  token: string;
}

function Export(props: ExportProps) {
  const [error, setError] = useState<string | undefined>();

  const projectExportAsPDF = () => {
    ExportService.exportProjectsAsPDF(props.token);
  };

  const projectExportAsCSV = () => {
    ExportService.exportProjectsAsCSV(props.token);
  };

  return (
    <>
      {error && (
        <Alert severity="error" sx={{ mb: 4 }}>
          <AlertTitle>Fehler</AlertTitle>
          {error}
        </Alert>
      )}
      <Box component="div" display="flex" flexDirection="column" width="100%">
        <Typography component="h1" variant="h4">
          Daten-Export
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Projekte
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Exportiert die Projekte inklusive der Schülerinnen/Schüler, die es gewählt haben.
                </Typography>
              </CardContent>
              <CardActions>
                <Button onClick={projectExportAsCSV}>CSV</Button>
                <Button onClick={projectExportAsPDF}>PDF</Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Export;
