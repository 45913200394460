import axios from "axios";
import { Teacher } from "./App";

export class TeacherService {
  public static getAllTeachers(token: string): Promise<Teacher[]> {
    return axios
      .get<Teacher[]>("/api/teachers", {
        headers: { token: token },
      })
      .then((data) => data.data);
  }
}
