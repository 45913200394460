import axios from "axios";
import { Project } from "./App";

export class ProjectService {
  public static getAllProjects(token: string): Promise<Project[]> {
    return axios
      .get<Project[]>("/api/projects", {
        headers: { token: token },
      })
      .then((data) => data.data);
  }

  public static getSingleProject(token: string, id: number): Promise<Project> {
    return axios
      .get<Project>(`/api/projects/${id}`, {
        headers: { token: token },
      })
      .then((data) => data.data);
  }

  public static updateProject(
    token: string,
    id: number,
    project: Project
  ): Promise<Project> {
    return axios
      .post<Project>(`/api/projects/${id}`, project, {
        headers: { token: token },
      })
      .then((data) => data.data);
  }

  public static addProject(token: string, project: Project): Promise<Project> {
    return axios
      .post<Project>(`/api/projects`, project, {
        headers: { token: token },
      })
      .then((data) => data.data);
  }

  public static deleteProject(token: string, projectId: number): Promise<void> {
    return axios.delete(`/api/projects/${projectId}`, {
      headers: { token: token },
    });
  }

  public static setProjectTeachers(
    token: string,
    projectId: number,
    teacherIds: number[]
  ): Promise<Project> {
    return axios
      .post<Project>(
        `/api/projects/${projectId}/teachers`,
        { teachers: teacherIds },
        { headers: { token: token } }
      )
      .then((data) => data.data);
  }
}
