import { Edit } from "@mui/icons-material";
import { Box, Button } from "@mui/material";

export function EditButtonCell(props: any): JSX.Element {
  return (
    <Box component="div" display="flex" justifyContent="center">
      <Button color="info" onClick={props.onClick}>
        <Edit />
      </Button>
    </Box>
  );
}

export default EditButtonCell;
